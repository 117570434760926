<template>
  <template v-if="!!formInputs?.form?.common">
    <v-card class="my-4 pa-4">
      <v-card-title class="headline">COMMON</v-card-title>
      <v-card-text>
        <div class="row mr-3 v-theme--light">
          <Input
              label="デバイスID"
              v-model="form.machine_id"
              :isShow="formFieldOptions.machineId.isShow"
              :isRequired="formFieldOptions.machineId.isRequired"
              :formInputs="formInputs"
              :readonly="true"
              :errors="formFieldOptions.machineId.errors"
          />
          <template v-for="(title, key) in keys" v-bind:key="key">
            <Input
              :label="title"
              v-model="form[key]"
              :isShow="formFieldOptions[key].isShow"
              :isRequired="formFieldOptions[key].isRequired"
              :formInputs="formInputs"
              :readonly="selectDefault && readonlyInputs.includes(key)"
              :errors="formFieldOptions[key].errors"
              v-if="!isOperationButtonKeys(key) && !isPoweroffKeys(key)"
            >
              <template v-if="key === 'overwrite_deny'">
                <pre style="color: blue">
ALL_ALLOW = 全て上書き設定
ALL_DENY = 全て上書き禁止
NORMAL_DENY = 常時録画上書き禁止
EVENT_DENY = イベント録画上書き禁止
DIGITACHO_DENY = デジタコデータ上書き禁止
                </pre>
              </template>
            </Input>
          </template>
        </div>

        <div class="mr-3 v-theme--light">
          <Toggle
              :label="keys['automatic_cancellation_on']"
              v-model="form['automatic_cancellation_on']"
              :formInputs="formInputs"
              :isShow="formFieldOptions['automatic_cancellation_on'].isShow"
              :isRequired="formFieldOptions['automatic_cancellation_on'].isRequired"
              :disabled="selectDefault && readonlyInputs.includes('automatic_cancellation_on')"
          />
        </div>
        <div class="row align-items-center no-padding" v-if="form['automatic_cancellation_on'] === 'ON'">
          <template v-for="(title, key) in filteredOperationButtonKeys" v-bind:key="key">
          <div class="col">
            <Input
                type="number"
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :min="1"
                :max="operationButtonMax(key)"
                :colClassNumber="0"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes('key')"
                :errors="formFieldOptions[key].errors"
                v-if="key !== 'automatic_cancellation_on'"
            />
          </div>
          </template>
      </div>

      <div class="mr-3 v-theme--light">
        <Toggle
            :label="keys['delay_poweroff_on']"
            v-model="form['delay_poweroff_on']"
            :formInputs="formInputs"
            :isShow="formFieldOptions['delay_poweroff_on'].isShow"
            :isRequired="formFieldOptions['delay_poweroff_on'].isRequired"
            :disabled="selectDefault && readonlyInputs.includes('delay_poweroff_on')"
        />
      </div>
      <div class="row align-items-center no-padding" v-if="form['delay_poweroff_on'] === 'ON'">
        <template v-for="(title, key) in filteredPoweroffButtonKeys" v-bind:key="key">
          <div class="col">
            <Input
                type="number"
                :label="title"
                v-model="form[key]"
                :isShow="formFieldOptions[key].isShow"
                :isRequired="formFieldOptions[key].isRequired"
                :min="poweroffMin(key)"
                :max="poweroffMax(key)"
                :isDecimal="key === 'delay_poweroff_voltage'"
                :colClassNumber="0"
                :formInputs="formInputs"
                :readonly="selectDefault && readonlyInputs.includes('key')"
                :errors="formFieldOptions[key].errors"
            />
          </div>
        </template>
      </div>

      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import Input from '@/components/VehicleFormParts/SettingFormInput.vue';
import Toggle from "@/components/VehicleFormParts/SettingFormToggle.vue";

export default {
  props: {
    modelValue: {},
    formInputs: {},
    selectDefault: {
      type: Boolean,
      default: false,
    },
    errors: {},
    selfOfficeType: null,
  },
  data() {
    return {
      form: {},
      readonlyInputs: [],
      errorKeyPrefix: 'initial_params.common',
      selectOperationButtonOnOff: [
       { title: 'ON', value: 'ON' },
       { title: 'OFF', value: 'OFF' },
      ],
      keys: {
        ftp_on: 'FTPON',
        ftp_id: 'FTPID',
        ftp_pass: 'FTPPASS',
        alert_idling_first_sec: 'アイドリング超過時間（秒）：0～9999秒',
        alert_idling_second_sec: 'アイドリング警告繰返時間（秒）：0～9999秒',
        volume: '音量：0～5',
        alert_idling_sound: 'アイドリング警告アラート',
        gps_dr: 'GPSデットレコニング',
        camera_on: 'CAMERA_ON',
        overwrite_deny: 'OVERWRITE_DENY',
        wakeup_update: 'WAKEUP_UPDATE',
        upload_trip_data: 'UPLOAD_TRIP_DATA',
        automatic_cancellation_on: '作業ボタン自動解除設定',
        automatic_cancellation_speed: '作業ボタン自動解除条件設定(km/h)：1～20',
        automatic_cancellation_time: '作業ボタン自動解除条件設定(何秒間継続) : 1~30',
        delay_poweroff_on: 'デジタこ電源OFFタイマー設定',
        delay_poweroff_time: 'エンジンOFF後の起動時間(分) : 1~30',
        delay_poweroff_voltage: 'デジタコ強制終了バッテリー電圧(V) : 12.0~24.0',
      },
    };
  },
  methods: {
    isOperationButtonKeys(key) {
      return key === 'automatic_cancellation_on' || key === 'automatic_cancellation_speed' || key === 'automatic_cancellation_time';
    },
    isPoweroffKeys(key) {
      return key === 'delay_poweroff_on' || key === 'delay_poweroff_time' || key === 'delay_poweroff_voltage';
    },
    operationButtonMax(key) {
      return key === 'automatic_cancellation_speed' ? 20 : 30;
    },
    poweroffMin(key) {
      return key === 'delay_poweroff_time' ? 1 : 12.0;
    },
    poweroffMax(key) {
      return key === 'delay_poweroff_time' ? 30 : 24.0;
    },
    getShowAndRequiredAndErrors(parentKey, formKeys) {
      if (!this.formInputs?.form[parentKey]) {
        return {
          'isShow': false,
          'isRequired': false,
          'errors': null
        };
      }

      const keys = Object.keys(this.formInputs.form[parentKey]);

      const result = formKeys.map(formKey => {
        return {
          'isShow': keys.includes(formKey),
          'isRequired': !!this.formInputs.form[parentKey][formKey]?.required,
          'errors': this.errors[this.errorKeyPrefix + '.' + formKey]
        };
      });

      return {
        'isShow': result.every(item => item.isShow),
        'isRequired': result.every(item => item.isRequired),
        'errors': result.map(item => item.errors).filter(error => error)
      };
    }
  },
  computed: {
    filteredOperationButtonKeys() {
      const allowedKeys = [
        'automatic_cancellation_speed',
        'automatic_cancellation_time'
      ];

      return Object.fromEntries(
          Object.entries(this.keys).filter(([key]) => allowedKeys.includes(key))
      );
    },
    filteredPoweroffButtonKeys() {
      const allowedKeys = [
        'delay_poweroff_time',
        'delay_poweroff_voltage'
      ];

      return Object.fromEntries(
          Object.entries(this.keys).filter(([key]) => allowedKeys.includes(key))
      );
    },
    formFieldOptions() {
      const parentKey = 'common'

      const options = {
        machineId: this.getShowAndRequiredAndErrors(parentKey, ['machine_id']),
      };

      Object.keys(this.keys).forEach(key => {
        options[key] = this.getShowAndRequiredAndErrors(parentKey, [key]);
      });

      return options;
    },
  },
  watch: {
    modelValue: {
      handler() {
        this.form = this.modelValue;
      },
    },
    formInputs: {
      handler() {
        if (!this.formInputs?.form?.common) return;
        const key = Object.keys(this.formInputs.default_params)[0];
        this.readonlyInputs = Object.keys(
          this.formInputs.default_params[key]?.parameters?.common
        );
      },
    },
    form: {
      handler() {
        this.$emit('update:modelValue', this.form);
      },
      deep: true,
    },
  },
  components: {
    Toggle,
    Input,
  },
};
</script>

<style scoped>
.headline {
  font-size: 24px;
  font-weight: bold;
  color: #0088a6;
}
</style>
