

import {defineComponent} from "vue";
import Api from "@/services/api/ApiServiceFabrick";
import {getDeviceFirmwareListRequest, perPage, sortField} from "@/types/requests/getDeviceFirmwareListRequest";
import {
  calculatePageCount,
  formatDate
} from "@/services/functions";
import DeviceFirmware from "@/types/DeviceFirmware";
import DeviceList from "@/components/Firmware/DeviceList.vue";
import PagenateItems from "@/components/PagenateItems.vue";
import { sortType, DEVICE_FIRMWARE_DEFAULT_DAYS_RANGE } from "@/services/consts";
import DeviceListSearchForm from "@/components/Firmware/DeviceListSearchForm.vue";
import AttachFirmwareForm from "@/components/Firmware/AttachFirmwareForm.vue";
import {attachFirmwareToDevicesRequest} from "@/types/requests/attachFirmwareToDevicesRequest";
import {detachFirmwareFromDevicesRequest} from "@/types/requests/detachFirmwareFromDevicesRequest";
import TableItemCount from "@/components/template/TableItemCount.vue";
import getFirmwareVersionsResponse from "@/types/responses/getFirmwareVersionsResponse";
import ManualLink from "@/components/ManualLink.vue";
import AuthorityCacheApi from "@/services/models/AuthorityCacheApi";
import i18n from '@/lang/i18n';

export default defineComponent({
  data() {
    const currentDate = new Date()
    const defaultStartOfRange = new Date(currentDate)
    defaultStartOfRange.setDate(defaultStartOfRange.getDate() - DEVICE_FIRMWARE_DEFAULT_DAYS_RANGE)

    return {
      pageName: "firmwareUpdate",
      currentPage: 1 as number,
      totalPage: 0 as number,
      devices: [] as Array<DeviceFirmware>,
      deviceListFilter: {
        version: undefined as undefined | string,
        device_id: undefined as undefined | number,
        ar_device_type_ids: undefined as undefined | number[],
        device_operation_office_companies: undefined as undefined | number[],
        firmware_id: undefined as undefined | number,
        vehicle_id: undefined as undefined | number,
        activationDateRangeStart: formatDate(defaultStartOfRange),
        activationDateRangeEnd: formatDate(currentDate),
      },
      deviceListSort: {
        sort_type: undefined as sortType | undefined,
        sort: undefined as sortField | undefined,
      },
      isDeviceListLoading: false as boolean,
      selectedDevices: [] as Array<string>,
      isFirmwareDeviceAttaching: false as boolean,
      isFirmwareDeviceDetaching: false as boolean,
      devicesPerPage: 50 as number,
      allDevicesCount: 0 as number,
      firmwares: [] as getFirmwareVersionsResponse,
      updateAuthority: true as boolean,
    }
  },
  props: {
    showFlash: {
      type: Function,
      required: true,
    },
    loginWithRedirect: {
      type: Function,
      required: true,
    },
  },
  name: "DeviceFirmwareView",
  setup() {
    document.title =  i18n.global.t('pages.deviceFirmware.title')
    return {
      i18n
    }
  },
  mounted() {
    this.getAuthorities()
    this.getDevices()
    this.getFirmwares()
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue): void {
        if (newValue !== oldValue) {
          this.getDevices()
        }
      }
    },
    devicesPerPage: {
      handler(): void {
        this.getDevices()
      }
    }
  },
  computed: {
    getDevicesListRequest(): getDeviceFirmwareListRequest {

      const request = {
        page: this.currentPage,
        per_page: this.devicesPerPage,
      } as getDeviceFirmwareListRequest;

      if (this.deviceListFilter.version) {
        request.version = this.deviceListFilter.version
      }

      if (this.deviceListFilter.device_id) {
        request.device_id = this.deviceListFilter.device_id
      }

      if (this.deviceListFilter.ar_device_type_ids) {
        request.ar_device_type_ids = this.deviceListFilter.ar_device_type_ids
      }

      if (this.deviceListFilter.device_operation_office_companies) {
        request.device_operation_office_companies = this.deviceListFilter.device_operation_office_companies
      }

      if (this.deviceListFilter.firmware_id) {
        request.firmware_id = this.deviceListFilter.firmware_id
      }

      if (this.deviceListFilter.vehicle_id) {
        request.vehicle_id = this.deviceListFilter.vehicle_id
      }

      if (this.deviceListFilter.activationDateRangeStart) {
        request.activation_date_range_start = this.deviceListFilter.activationDateRangeStart
      }

      if (this.deviceListFilter.activationDateRangeEnd) {
        request.activation_date_range_end = this.deviceListFilter.activationDateRangeEnd
      }

      if (this.deviceListSort.sort_type && this.deviceListSort.sort) {
        request.sort = this.deviceListSort.sort;
        request.sort_type = this.deviceListSort.sort_type;
      }

      return request
    },
    detachBtnText(): string {
      return this.isFirmwareDeviceDetaching ?
      i18n.global.t('pages.deviceFirmware.button.deleting') :
      i18n.global.t('pages.deviceFirmware.button.delete');
    },
    devicesPerPageVariants(): Array<number> {
      return [perPage.ITEM_50, perPage.ITEM_100, perPage.ITEM_200]
    },
    selectedFirmwareRelationIds(): Array<number> {
      const result: Array<number> = []
      this.devices.map(device => {
        const key = this.makeKey(device)
        if (this.selectedDevices.includes(key) && device.firmware?.id) {
          result.push(device.firmware?.firmware_device_relation_id)
        }
      });
      return result
    },
    selectedDeviceTypes(): Array<number> {
      const result: Array<number> = []
      this.devices.map(device => {
        const key = this.makeKey(device)
        if (this.selectedDevices.includes(key) && device.type?.id) {
          result.push(device.type?.id)
        }
      });
      return [...new Set(result)]
    },
    availableToSelectFirmwares(): getFirmwareVersionsResponse {
      if (this.selectedDeviceTypes.length > 0) {

        return this.firmwares.filter(firmware => {
          for ( let i=0; i<this.selectedDeviceTypes.length; i++ ){
            if ( !firmware.device_type_ids.includes(this.selectedDeviceTypes[i]) ){
              return false
            }
          }
          return true
        });

      } else {
        return this.firmwares
      }
    },
    isDetachButtonDisabled(): boolean {
      return this.selectedFirmwareRelationIds.length < 1
    },
    isAttachButtonDisabled(): boolean {
      return this.selectedDevices.length < 1
    },
  },
  methods: {
    makeKey(device: DeviceFirmware): string {
      const firmwareId = device?.firmware?.id;
      const deviceId = device.id;
      return `${deviceId}.${firmwareId}`;
    },
    findDeviceByKey(key: string): DeviceFirmware|undefined {
      const deviceId = key.split('.')[0];
      return this.devices.find((device) => device.id == deviceId)
    },
    getSelectedDeviceIds(): string[] {
      return this.selectedDevices.map((key) => this.findDeviceByKey(key)?.id ?? '');
    },
    async getAuthorities(): Promise<void> {
      const authorityList = await AuthorityCacheApi.getWithParse();
      this.updateAuthority = authorityList["octlink.firmware.update"];
    },
    getDevices(): void {
      this.isDeviceListLoading = true

      Api().getDeviceFirmwareList(this.getDevicesListRequest).then((data) => {
        this.devices = data.devices
        this.totalPage = calculatePageCount(data.pagination.all_elements_count, data.pagination.elements_per_page)
        this.isDeviceListLoading = false
        this.allDevicesCount = data.pagination.all_elements_count
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.deviceFirmware.errors.getDeviceList'), "error", false)
        }
        this.isDeviceListLoading = false
      })
    },
    getFirmwares(): void {
      Api().autoCompleteFirmwaresVersions().then((data) => {
        this.firmwares = data
      })
    },
    handleChangeSort(sortParams: { sort_type: sortType | undefined, sort: sortField | undefined }): void {
      this.deviceListSort = sortParams
      this.getDevices()
    },
    handleChangeSearchForm(form: {
      version: string | undefined,
      device_id: number | undefined,
      ar_device_type_ids: number[] | undefined,
      device_operation_office_companies: number[] | undefined,
      firmware_id: number | undefined,
      vehicle_id: number | undefined,
      activationDateRangeStart: string,
      activationDateRangeEnd: string,
    }): void {
      this.deviceListFilter = form
    },
    updateSelectedItems(selectedDevices: Array<string>): void {
      this.selectedDevices = selectedDevices
    },
    createRequestAndAttachFirmwareToDevices(params: { firmware_id: number, activate_date: Date }): void {
      const request: attachFirmwareToDevicesRequest | undefined = this.createAttachFirmwareToDevices(params)
      if (request) {
        this.attachFirmwareToDevices(request)
      }
    },
    createAttachFirmwareToDevices(params: { firmware_id: number, activate_date: Date }): attachFirmwareToDevicesRequest | undefined {
      if (this.selectedDevices.length < 1) {
        return
      }
      return {
        firmware_id: params.firmware_id,
        activate_date: formatDate(params.activate_date),
        ar_device_ids: this.getSelectedDeviceIds()
      }
    },
    attachFirmwareToDevices(request: attachFirmwareToDevicesRequest): void {

      this.isFirmwareDeviceAttaching = true

      Api().attachFirmwareToDevices(request).then(() => {
        this.isFirmwareDeviceAttaching = false
        this.getDevices()
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.deviceFirmware.errors.applyFirmware'), "error", false)
            break
          case 400:
            this.showFlash(res.data?.message, "error", false)
            break
        }
        this.isFirmwareDeviceAttaching = false
      })
    },
    createRequestAndDetachFirmwareToDevices(): void {
      if (this.isFirmwareDeviceDetaching) {
        return
      }
      const request: detachFirmwareFromDevicesRequest | undefined = this.createDetachFirmwareToDevicesRequest()
      if (request) {
        this.detachFirmwareFromDevices(request)
      }
    },
    createDetachFirmwareToDevicesRequest(): detachFirmwareFromDevicesRequest | undefined {
      if (this.selectedFirmwareRelationIds.length < 1) {
        return
      }

      return {
        ar_relations_ids: this.selectedFirmwareRelationIds
      }
    },
    detachFirmwareFromDevices(request: detachFirmwareFromDevicesRequest): void {

      this.isFirmwareDeviceDetaching = true

      Api().detachFirmwareFromDevices(request).then(() => {
        this.isFirmwareDeviceDetaching = false
        this.getDevices()
      }).catch((error) => {
        const res = error.response
        switch (res.status) {
          case 401:
            this.loginWithRedirect({appState: {targetUrl: location.pathname}})
            break
          case 403:
            this.$router.push({
              name: 'dashboard',
            })
            break
          case 422:
            this.showFlash(i18n.global.t('pages.deviceFirmware.errors.deleteFirmware'), "error", false)
            break
          case 400:
            this.showFlash(res.data?.message, "error", false)
            break
        }
        this.isFirmwareDeviceDetaching = false
      })
    },
    resetPaginationAndGetDevices(): void {
      this.resetPagination()
      this.getDevices()
    },
    resetPagination(): void {
      this.currentPage = 1
    },
    getFirmwareMemo(firmwareID: number | undefined): string | undefined {
      if (!firmwareID) {
        return
      }
      const firmware = this.firmwares.find(firmware => firmware.id == firmwareID)
      if (!firmware) {
        return
      }
      return firmware.memo;
    },
  },
  components: {
    DeviceList,
    pagenate: PagenateItems,
    DeviceListSearchForm,
    AttachFirmwareForm,
    TableItemCount,
    ManualLink
  }
})
