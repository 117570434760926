
import { defineComponent, PropType } from 'vue';
import { formatDate } from '@/services/functions'
import { DEVICE_FIRMWARE_DEFAULT_DAYS_RANGE } from "@/services/consts";
import Datepicker from '@vuepic/vue-datepicker';
import FirmwareVersionAutoComplete from '@/components/AutoCompletes/FirmwareVersionAutoComplete.vue';
import MultipleDeviceTypeAutoComplete from '@/components/AutoCompletes/MultipleDeviceTypeAutoComplete.vue';
import MultipleOperationOfficeAutoComplete from '@/components/AutoCompletes/MultipleOperationOfficeAutoComplete.vue';
import DeviceIdAutoComplete from '@/components/AutoCompletes/DeviceIdAutoComplete.vue';
import VehicleAutoComplete from '@/components/AutoCompletes/VehicleAutoComplete.vue';
import getOperationOffice from '@/types/responses/getOperationOffice';
import FirmwareVersionsType from '@/types/FirmwareVersionsType';
import getDeviceResponse from '@/types/responses/getDevice';
import getVehicleResponse from '@/types/responses/getVehicle';
import deviceType from '@/types/DeviceType';
import ValidationErrors from '@/components/Common/ValidationErrors.vue';
import i18n from '@/lang/i18n';

const currentDate = new Date()
const defaultStartOfRange = new Date(currentDate)
defaultStartOfRange.setDate(defaultStartOfRange.getDate() - DEVICE_FIRMWARE_DEFAULT_DAYS_RANGE)

export default defineComponent({
  setup() {
    return {
      i18n
    };
  },
  data() {
    return {
      form: {
        version: undefined as undefined | string,
        device_id: undefined as undefined | number,
        ar_device_type_ids: [] as [] | number[],
        device_operation_office_companies: undefined as undefined | number[],
        firmware_id: undefined as undefined | number,
        vehicle_id: undefined as undefined | number,
        activationDateRangeStart: formatDate(defaultStartOfRange),
        activationDateRangeEnd: formatDate(currentDate),
      },
      operation_office_company: [] as Array<getOperationOffice>,
      device: undefined as getDeviceResponse | undefined,
      vehicle: undefined as getVehicleResponse | undefined,
      firmware: undefined as undefined | FirmwareVersionsType,
      formHidden: false as boolean,
      selectedDeviceTypes: [] as Array<deviceType>,
      errors: {} as {[key: string]: string[]},
    };
  },
  props: {
    isDeviceListLoading: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
  },
  name: 'DeviceListSearchForm',
  computed: {
    searchAreaClass() {
      return this.formHidden ? 'search-area-hide p-4' : 'search-area p-4';
    },
    filterBtnText(): string {
      return this.isDeviceListLoading ?
        i18n.global.t('pages.common.button.loading') :
        i18n.global.t('pages.common.button.exec');
    },
  },
  emits: ['form-change', 'search'],
  watch: {
    form: {
      handler() {
        this.$emit('form-change', this.form);
      },
      deep: true,
    },
    operation_office_company: {
      handler() {
        if (this.operation_office_company) {
          this.form.device_operation_office_companies =
            this.operation_office_company.map((company) => company.id);
        } else {
          this.form.device_operation_office_companies = undefined;
        }
      },
      deep: true,
    },
    device: {
      handler() {
        if (this.device) {
          this.form.device_id = this.device.id;
        } else {
          this.form.device_id = undefined;
        }
      },
      deep: true,
    },
    vehicle: {
      handler() {
        if (this.vehicle) {
          this.form.vehicle_id = this.vehicle.id;
        } else {
          this.form.vehicle_id = undefined;
        }
      },
      deep: true,
    },
    firmware: {
      handler() {
        this.form.firmware_id = this.firmware?.id;
      },
      deep: true,
    },
    selectedDeviceTypes: {
      handler() {
        this.form.ar_device_type_ids = this.selectedDeviceTypes.map(
          (deviceType) => deviceType.id
        );
      },
      deep: true,
    },
  },
  methods: {
    validateRange(): boolean {
      this.errors['activationDateRangeEnd'] = []
      if (!this.form.activationDateRangeStart || !this.form.activationDateRangeEnd) {
        return true
      }
      const start = new Date(this.form.activationDateRangeStart)
      const end = new Date(this.form.activationDateRangeEnd)
      if (start.getTime() > end.getTime()) {
        this.errors['activationDateRangeEnd'] = [i18n.global.t('pages.firmwareList.form.validate.invalidRange')]
        return false
      }
      return true
    },
    handleClickSearch(): void {
      if (this.isDeviceListLoading) {
        return;
      }
      if (!this.validateRange()) {
        return;
      }
      this.$emit('search', this.form);
    },
    clearForm(): void {
      this.operation_office_company = [];
      this.firmware = undefined;
      this.selectedDeviceTypes = [];
      this.form.version = undefined;
      this.form.device_id = undefined;
      this.form.device_operation_office_companies = undefined;
      this.vehicle = undefined;
      this.device = undefined;
      this.form.activationDateRangeStart = formatDate(defaultStartOfRange)
      this.form.activationDateRangeEnd = formatDate(currentDate)
    },
    toggleSidebar(): void {
      this.formHidden = !this.formHidden;
    },
  },
  components: {
    FirmwareVersionAutoComplete,
    MultipleDeviceTypeAutoComplete,
    MultipleOperationOfficeAutoComplete,
    DeviceIdAutoComplete,
    VehicleAutoComplete,
    Datepicker,
    ValidationErrors,
  },
});
