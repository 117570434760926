import deviceType from "@/types/DeviceType";
import i18n from '@/lang/i18n';
export * from '@/services/consts/cvr_upload';
export * from '@/services/consts/operation_office';
export * from '@/services/consts/job_status';

export const AUTHORITY_CACHE_RETRY_COUNT = 10
export const AUTHORITY_CACHE_RETRY_INTERVAL = 100
export const DELIVERY_PLAN_RULE_2024_START_DATE_TIME = '2024-04-01 00:00:00';

export const WORK_TYPES = [
  "leaving_warehouse",
  "loading",
  "unloading",
  "shipping",
  "incidental_work",
  "refueling",
  "wait",
  "break",
  "rest",
  "returning_warehouse",
];

export const WORK_EVENT_TYPES = {
  leaving_warehouse: "出庫",
  loading: "荷積",
  unloading: "荷卸",
  shipping: "荷待",
  incidental_work: "付帯作業",
  refueling: "給油",
  wait: "待機",
  break: "休憩",
  rest: "休息",
  returning_warehouse: "帰庫",
  stopping: "停車",
};

export const SELECT_WORK_EVENT_TYPES = {
  loading: "荷積",
  unloading: "荷卸",
  shipping: "荷待",
  incidental_work: "付帯作業",
  refueling: "給油",
  wait: "待機",
  break: "休憩",
  rest: "休息",
};

interface routingAuthority {
  [key: string]: string;
}

export const routingAuthorityNames = {
  Dashboard: "octlink.dashborad.read",
  DailyReport: "octlink.daily_report.read",
  DailyReportLayout: "octlink.daily_report_layout.read",
  Spot: "octlink.spot.read",
  SafeDrivingSetting: "octlink.driving_score.read",
  DailyReportHistory: "octlink.daily_report.read",
  MonthlyReport: "octlink.monthly_report.read",
  SafeDrivingReport: "octlink.safe_driving_report.read",
  OperationOffice: "octlink.operation_office.read",
  InstallationOffice: "octlink.installation_office.read",
  InstallationOfficeForm: "octlink.installation_office.form",
  Vehicle: "octlink.vehicle.read",
  VehicleForm: "octlink.vehicle.read",
  Accounts: "octlink.user.read",
  Firmwares: "octlink.firmware.read",
  FirmwareDevices: "octlink.firmware.read",
  AccountForm: "octlink.user.read",
  Authority: "octlink.user_role.read",
  Every24hoursReport: "octlink.every_24hours_delivery_report.read",
  TrackingService: "octlink.fleet_tracking_service.read",
  OperationOfficeEditForm: "octlink.operation_office.update",
  OperationOfficeCreateForm: "octlink.operation_office.create",
  DownloadData: "octlink.download_data.output",
  DownloadDataHistories: "octlink.download_data.read",
  Groups: "octlink.group.read",
  GroupForm: "octlink.group.read",
  CvrConvert: "octlink.cvr_upload.read",
  Temperature: "octlink.temperature.read",
  Device: "octlink.device.read",
  AllowAccessOffice: "octlink.allow_access_office.read",
  DataLinkage: 'octlink.data_linkage.read',
  SubManagementOffice: 'octlink.submanagement_office.read',
  SubManagementOfficeForm: 'octlink.submanagement_office.form',
  roleAssignment: 'octlink.role_attach.update',
  Departments: 'octlink.department.read',
  UserRoles: 'octlink.user_role.read',
} as routingAuthority;

interface permissionDispplayNameType {
  [key: string]: string;
}
export const permissionDispplayName = {
  summary: "ダッシュボード",
  firmware: "ファームウェア",
  daily_report: "日報",
  daily_report_layout: "日報レイアウト",
  every_24hours_delivery_report: "24時間毎データ",
  monthly_report: "月報",
  temperature: "温度管理",
  safe_driving_report: "安全運転帳票",
  user: "アカウント",
  vehicle: "車両管理",
  device: "デバイス管理",
  submanagement_office: "準管理会社",
  operation_office: "運営会社",
  spot: "地点登録",
  driving_score: "運転スコア",
  group: "グループ",
  installation_office: "設置会社",
  allow_access_office: "運営会社参照許可",
  user_role: "権限管理",
  role_attach: "権限付与",
  department: "組織",
  download_data: "データ出力",
  data_linkage: "データ連携",
  cvr_upload: "CVR動画アップロード",
  it_tenko: "IT点呼",
  fleet_tracking_service: "動態管理サービス",
}as permissionDispplayNameType;

export const downloadDataFormats = [
  {
    title: "ロジ勤怠",
    value: "logi_attendance",
    file_type: "csv",
    request_type:
      "App\\Services\\OutputReports\\DownloadData\\LogiAttendanceCsvCreateService",
  },
  {
    title: "配達くん",
    value: "haitatsu",
    file_type: "csv",
    request_type:
      "App\\Services\\OutputReports\\DownloadData\\HaitatsuCsvCreateService",
  },
];

export const tenkoTypes = [
  { title: "IT点呼キーパー", value: "ittenko" },
  { title: "ホワイト安全キーパー", value: "white_safety" },
];

export const DEVICE_TYPE_C01_0 = "C01.0";
export const DEVICE_TYPE_C01_0_ID = 1;
export const DEVICE_TYPE_C01_5 = "C01.5";
export const DEVICE_TYPE_C01_5_ID = 2;
export const DEVICE_TYPE_C01_5N = "C01.5N";
export const DEVICE_TYPE_C01_5N_ID = 3;
export const DEVICE_TYPE_C300 = "C300";
export const DEVICE_TYPE_C300_ID = 4;
export const DEVICE_TYPE_C500 = "C500";
export const DEVICE_TYPE_C500_ID = 5;

export const deviceTypes: deviceType[] = [
  {
    type: DEVICE_TYPE_C01_0,
    id: DEVICE_TYPE_C01_0_ID,
  },
  {
    type: DEVICE_TYPE_C01_5,
    id: DEVICE_TYPE_C01_5_ID,
  },
  {
    type: DEVICE_TYPE_C01_5N,
    id: DEVICE_TYPE_C01_5N_ID,
  },
  {
    type: DEVICE_TYPE_C300,
    id: DEVICE_TYPE_C300_ID,
  },
  {
    type: DEVICE_TYPE_C500,
    id: DEVICE_TYPE_C500_ID,
  },
];

export const visibleDeviceTypes = deviceTypes.filter((type) => {
  return type.id != 4;
});

export enum sortType {
  ASK = "asc",
  DESC = "desc",
}

export enum accountType {
  managementOffice = "ManagementOffice",
  subManagementOffice = "SubManagementOffice",
  installationOffice = "InstallationOffice",
  operationOffice = "OperationOffice",
}

export const dailyReportPageStructures = [
  null,
  "day",
  "operation",
  null,
  "by_driver",
];
export const dailyReportPageCompositions = [
  { key: "day", label: "日別", data: 0 },
  { key: "operation", label: "運行別", data: 2 },
  { key: "by_driver", label: "まとめて（ドライバー別）", data: 4 },
];

export const dailyReportChartDisplay = [
  { key: "speed", label: "速度", data: "" },
  { key: "high_speed", label: "高速", data: "" },
  { key: "actual_vehicle", label: "実車", data: "" },
  { key: "stop", label: "停止", data: "" },
  { key: "loading", label: "荷積", data: "" },
  { key: "unloading", label: "荷卸", data: "" },
  { key: "shipping", label: "荷待", data: "" },
  { key: "incidental_work", label: "付帯作業", data: "" },
  { key: "refueling", label: "給油", data: "" },
  { key: "wait", label: "待機", data: "" },
  { key: "break", label: "休憩", data: "" },
  { key: "rest", label: "休息", data: "" },
  { key: "long_time_continuous_operation", label: "長時間連続運転", data: "" },
  { key: "over_speed", label: "速度超過", data: "" },
  { key: "rapid_acceleration", label: "急加速", data: "" },
  { key: "rapid_deceleration", label: "急減速", data: "" },
  {
    key: "sharp_steering_wheel_and_steep_turn",
    label: "急ﾊﾝﾄﾞﾙ・急旋回 ",
    data: "",
  },
  { key: "excess_rotation", label: "回転数超過 ", data: "" },
  { key: "idling", label: "アイドリング", data: "" },
  { key: "rotational_speed", label: "回転数", data: "" },
  { key: "temperature_sensor", label: "温度管理", data: "" },
];


export const DRIVER_ROLE_TYPE = 2;
export const ADMIN_ROLE_TYPE = 1;

export const GENERATE_ACCOUNT_PASSWORD_PREFIX = "Map@";

export const GENERATE_EMAIL_DOMAIN = ".y.octlink.jp";

export const SENSOR_SETTINGS_MAX_SCALE = 135;
export const SENSOR_SETTINGS_MIN_SCALE = -30;
export const SENSOR_CHART_STEP_SCALE = 5;
export const SENSOR_CHART_STEP_SCALE_CHANGE_THRESHOLD = 50;
export const SENSOR_SETTINGS_SELECTABLE_LIST_LENGTH = ((SENSOR_SETTINGS_MAX_SCALE - SENSOR_SETTINGS_MIN_SCALE) / SENSOR_CHART_STEP_SCALE) + 1;
export const SENSOR_SETTINGS_SELECTABLE_LIST = Array(SENSOR_SETTINGS_SELECTABLE_LIST_LENGTH).fill(SENSOR_SETTINGS_MAX_SCALE).map((v, i) => (v - i * SENSOR_CHART_STEP_SCALE));

export const OUTPUT_PDF_TYPE_TEMPERATURE_GRAPH = "App\\Services\\OutputReports\\Sensors\\PdfCreateService";
export const OUTPUT_PDF_TYPE_TEMPERATURE_LIST = "App\\Services\\OutputReports\\Sensors\\PdfCreateListService";
export const SUB_MANAGEMENT_OFFICE_CSV_IMPORT_TYPE = "App\\Services\\Csv\\Imports\\SubManagementOfficeService"
export const SUB_MANAGEMENT_OFFICE_ICE_EXPORT_TYPE = "App\\Services\\Csv\\Exports\\SubManagementOfficeService"

export const CAMERA_ON_KEY = "camera_on";

export const UNEXPECTED_EVENT_KEY = "unexpectedEvent";
export const SPECIAL_EXTENSION_KEY = "specialExtension";

export const MANAGEMENT_OFFICE_TYPE = 'App\\Models\\ManagementOffice';
export const SUB_MANAGEMENT_OFFICE_TYPE = 'App\\Models\\SubManagementOffice'
export const INSTALLATION_OFFICE_TYPE = 'App\\Models\\InstallationOffice'
export const OPERATION_OFFICE_TYPE = 'App\\Models\\OperationOffice';
export const DEPARTMENT_OFFICE_TYPE = 'App\\Models\\Department';

export const ORGANIZATION_TYPE = [
  {"id": 1, "name": "管理会社", "type": MANAGEMENT_OFFICE_TYPE},
  {"id": 2, "name": "準管理会社", "type": SUB_MANAGEMENT_OFFICE_TYPE},
  {"id": 3, "name": "設置会社", "type": INSTALLATION_OFFICE_TYPE},
  {"id": 4, "name": "運営会社", "type": OPERATION_OFFICE_TYPE},
  {"id": 5, "name": "組織", "type": DEPARTMENT_OFFICE_TYPE},
]

export const FIRST_LEVEL_DEPARTMENT_KEY = 1
export const SECOND_LEVEL_DEPARTMENT_KEY = 2

export const DEPARTMENT_SELECTABLE_LEVEL = [
  { key: FIRST_LEVEL_DEPARTMENT_KEY, label: i18n.global.t('pages.departments.createOrUpdateModal.firstLevel') },
  { key: SECOND_LEVEL_DEPARTMENT_KEY, label: i18n.global.t('pages.departments.createOrUpdateModal.secondLevel')},
];

export const MANAGER_ROLE_ID = 1
export const DRIVER_ROLE_ID = 2

export const DEVICE_FIRMWARE_DEFAULT_DAYS_RANGE = 7
